<template>
  <div>
    <button
      v-if="!userData && isAdminURL"
      class="authenticate-button"
      @click="authenticate"
    >
      <span class="material-icons">login</span>
      <span>Sign In with GitHub</span>
    </button>
    <div
      v-if="(!userData && isAdminURL) || incidentForm || incidentDetailsForm"
      @click="closeModal"
      class="blur"
    ></div>
    <FormIncidentDetails
      v-if="incidentDetailsForm"
      :editIncidentId="editIncidentId"
      :editIncidentActionId="editIncidentActionId"
      @closeModal="closeModal"
    />
    <FormIncident
      v-if="incidentForm"
      :editIncidentId="editIncidentId"
      @closeModal="closeModal"
    />
    <div class="status-card">
      <StatusLogo :status="status" />
      <StatusDescription
        :status="status"
        :limitedOperationalAmount="limitedOperationalAmount"
        :lastIncident="lastIncident"
      />
      <div class="status-per-system">
        <SystemStatus
          v-for="system in systems"
          :key="system.name"
          :system="system"
          :isAdmin="isAdmin"
          @computeFavicon="computeFavicon"
        />
      </div>
      <CurrentIncidents
        v-if="(isAdmin && isAdminURL) || currentIncidents.length"
        :currentIncidents="currentIncidents"
        :isAdmin="isAdmin"
        @showModal="showModal"
      />
      <PastIncidents :pastIncidents="pastIncidents" :isAdmin="isAdmin" />
    </div>
  </div>
</template>

<script>
import admins from '../data/admins';

import moment from 'moment';
import { mapState } from 'vuex';

import { firebase, provider } from '../services/firebase.js';

import favicon from '../mixins/favicon.js';

import StatusLogo from '../components/StatusLogo.vue';
import StatusDescription from '../components/StatusDescription.vue';
import SystemStatus from '../components/SystemStatus.vue';
import CurrentIncidents from '../components/CurrentIncidents.vue';
import PastIncidents from '../components/PastIncidents.vue';
import FormIncident from '../components/FormIncident.vue';
import FormIncidentDetails from '../components/FormIncidentDetails.vue';

export default {
  name: 'Status',
  components: {
    CurrentIncidents,
    FormIncident,
    FormIncidentDetails,
    PastIncidents,
    StatusDescription,
    StatusLogo,
    SystemStatus,
  },
  mixins: [favicon],
  mounted() {
    this.computeFavicon();

    if (this.$route.name === 'Admin') {
      this.isAdminURL = true;
    }
  },
  data() {
    return {
      moment,
      // user data
      userData: null,
      isAdminURL: false,
      isAdmin: false,
      incidentForm: false,
      incidentDetailsForm: false,
      editIncidentId: null,
      editIncidentActionId: null,
      editStatusId: null,
    };
  },
  computed: {
    ...mapState({
      systems: (state) => state.systems,
      incidents: (state) => state.incidents,
    }),
    status() {
      if (this.systems.every((system) => system.operationalStatus === 1)) {
        return 'green';
      }
      if (this.systems.some((system) => system.operationalStatus === 3)) {
        return 'red';
      }
      return 'yellow';
    },
    fullyOperational() {
      return this.systems.every((system) => system.operationalStatus === 1);
    },
    limitedOperational() {
      return this.systems.some((system) => system.operationalStatus === 2);
    },
    limitedOperationalAmount() {
      const systemsAmount = this.systems.filter(
        (system) => system.operationalStatus === 2
      ).length;
      switch (systemsAmount) {
        case 1:
          return 'one';
        case 2:
          return 'two';
        case 3:
          return 'three';
        case 4:
          return 'four';
        case 5:
          return 'five';
        default:
          return '';
      }
    },
    nonOperational() {
      return this.systems.some((system) => system.operationalStatus === 3);
    },
    pastIncidents() {
      return this.incidents.filter(
        (incident) =>
          incident.isFixed &&
          moment().diff(incident.date, 'days') <= (this.isAdmin ? 9999 : 30)
      );
    },
    currentIncidents() {
      return this.incidents.filter((incident) => !incident.isFixed);
    },
    lastIncident() {
      return this.currentIncidents.length > 0
        ? 0
        : this.incidents.length > 0
        ? moment().diff(
            [...this.incidents].sort((a, b) => {
              return moment(b.date).unix() - moment(a.date).unix();
            })[0].date,
            'days'
          )
        : 0;
    },
  },
  methods: {
    authenticate() {
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(({ additionalUserInfo }) => {
          if (admins.includes(additionalUserInfo.username)) {
            this.isAdmin = true;
            this.userData = additionalUserInfo;
            return;
          }

          this.$router.push('/');
        });
    },
    showModal(type, id, actionId) {
      if (type === 'incident') {
        this.incidentForm = true;

        if (id !== undefined) {
          this.editIncidentId = id;
        }
      }

      if (type === 'incidentDetails') {
        this.incidentDetailsForm = true;
        this.editIncidentId = id;

        if (actionId !== undefined) {
          this.editIncidentActionId = actionId;
        }
      }
    },
    closeModal() {
      this.incidentForm = false;
      this.incidentDetailsForm = false;

      this.editIncidentId = null;
      this.editIncidentActionId = null;
      this.editStatusId = null;
    },
  },
};
</script>

<style lang="scss"></style>
