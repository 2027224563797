<template>
  <div>
    <h3 class="status-fire-heading">
      Current incidents
      <!-- todo: handle -->
      <span
        @click="$emit('showModal', 'incident')"
        v-if="isAdmin"
        class="add-incident material-icons"
        >add</span
      >
    </h3>
    <div class="status-fire-list">
      <div
        class="status-fire"
        v-for="(incident, index) in currentIncidents"
        :key="index"
      >
        <div class="status-fire-date">
          <span class="status-fire-time">{{
            moment(incident.date).format('dddd, MMMM Do YYYY, hh:mm A Z')
          }}</span>
          <vue-custom-tooltip
            :multiline="true"
            :label="generateFireDescription(incident.fire)"
            ><div class="status-fire-icons-container">
              <span
                class="status-fire-icons"
                v-for="(fire, index) in incident.fire"
                :key="index"
              >
                &#x1F525;
              </span>
            </div></vue-custom-tooltip
          >
        </div>
        <br />
        <div class="status-fire-description">
          {{ incident.description }}
          <span
            @click="$emit('showModal', 'incident', incident.id)"
            class="edit-incident material-icons"
            >edit</span
          >
          <span
            @click="toggleIncidentStatus(incident.id)"
            v-if="isAdmin"
            class="fix-incident material-icons"
            >done</span
          >
          <span
            @click="removeIncident(incident.id)"
            v-if="isAdmin"
            class="unfix-incident material-icons"
            >delete</span
          >
        </div>
        <!-- todo: handle -->
        <div class="status-fire-show-hide-details">
          <span
            @click="$emit('showModal', 'incidentDetails', incident.id)"
            v-if="isAdmin"
            class="material-icons"
            >add</span
          >
        </div>
        <div class="status-fire-actions">
          <div
            class="status-fire-action"
            v-for="(action, index) in incident.actions"
            :key="index"
          >
            <span class="status-fire-time"
              >{{ moment(action.time).format('MMMM Do YYYY, hh:mm A Z') }}
              <!-- todo: handle -->
              <template v-if="isAdmin">
                <span
                  @click="
                    $emit('showModal', 'incidentDetails', incident.id, index)
                  "
                  class="edit-incident material-icons"
                  >edit</span
                >
                <span
                  @click="removeIncidentAction(incident.id, index)"
                  class="remove-incident material-icons"
                  >delete</span
                >
              </template>
            </span>
            <div class="status-fire-action-details">
              <span class="status-fire-action-status">{{ action.status }}</span>
              -
              <span class="status-fire-action-description">{{
                action.description
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'CurrentIncidents',
  props: {
    currentIncidents: {
      type: Array,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      moment,
    };
  },
  methods: {
    generateFireDescription(fire) {
      if (fire === 1) {
        return 'There is a low probability for adverse effects caused by this issue';
      } else if (fire === 2) {
        return 'There is a medium probability adverse effects caused by this issue';
      } else {
        return 'There is a high probability for adverse effects caused by this issue';
      }
    },
    toggleIncidentStatus(id) {
      this.$store.commit('toggleIncidentStatus', {
        id,
        done: true,
      });
    },
    removeIncident(id) {
      this.$store.commit('removeIncident', { id });
    },
    removeIncidentAction(id, actionId) {
      this.$store.commit('removeIncidentAction', { id, actionId });
    },
  },
};
</script>

<style></style>
