<template>
  <div>
    <!-- todo: handle -->
    <h3 class="status-fire-heading">
      {{ isAdmin ? 'Incidents in the past' : 'Incidents in the past 30 days' }}
    </h3>
    <div class="status-fire-list">
      <div
        class="status-fire"
        v-for="(incident, incidentCounter) in pastIncidents"
        :key="incidentCounter"
      >
        <div class="status-fire-date">
          <span class="status-fire-time">{{
            moment(incident.date).format('dddd, MMMM Do YYYY, hh:mm A Z')
          }}</span>
          <vue-custom-tooltip
            :multiline="true"
            :label="generateFireDescription(incident.fire)"
            ><div class="status-fire-icons-container">
              <span
                class="status-fire-icons"
                v-for="(fire, index) in incident.fire"
                :key="index"
              >
                &#x1F525;
              </span>
            </div></vue-custom-tooltip
          >
        </div>
        <br />
        <div class="status-fire-description">
          {{ incident.description }}
          <span
            @click="toggleIncidentStatus(incident.id)"
            v-if="isAdmin"
            class="unfix-incident material-icons"
            >clear</span
          >
        </div>
        <div class="status-fire-show-hide-details">
          <span
            v-if="!expandContent.includes(incidentCounter)"
            @click="expandItem(incidentCounter)"
            class="material-icons"
            >add</span
          >
          <span
            v-if="!expandContent.includes(incidentCounter)"
            @click="expandItem(incidentCounter)"
            >Show details</span
          >
          <span
            v-if="expandContent.includes(incidentCounter)"
            @click="collapseItem(incidentCounter)"
            class="material-icons"
            >remove</span
          >
          <span
            v-if="expandContent.includes(incidentCounter)"
            @click="collapseItem(incidentCounter)"
            >Hide details</span
          >
        </div>
        <transition name="fade">
          <div
            class="status-fire-actions"
            v-show="expandContent.includes(incidentCounter)"
          >
            <div
              class="status-fire-action"
              v-for="(action, index) in incident.actions"
              :key="index"
            >
              <span class="status-fire-time"
                >{{ moment(action.time).format('MMMM Do YYYY, hh:mm A Z') }}
              </span>
              <div class="status-fire-action-details">
                <span class="status-fire-action-status">{{
                  action.status
                }}</span>
                -
                <span class="status-fire-action-description">{{
                  action.description
                }}</span>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div class="status-fire" v-if="pastIncidents.length === 0">
        No incidents
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'PastIncidents',
  props: {
    pastIncidents: {
      type: Array,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      moment,
      expandContent: [],
    };
  },
  methods: {
    generateFireDescription(fire) {
      if (fire === 1) {
        return 'There is a low probability for adverse effects caused by this issue';
      } else if (fire === 2) {
        return 'There is a medium probability adverse effects caused by this issue';
      } else {
        return 'There is a high probability for adverse effects caused by this issue';
      }
    },
    expandItem(idx) {
      this.expandContent.push(idx);
    },
    collapseItem(idx) {
      this.expandContent = this.expandContent.filter((item) => item !== idx);
    },
    toggleIncidentStatus(id) {
      this.$store.commit('toggleIncidentStatus', {
        id,
        done: false,
      });
    },
  },
};
</script>

<style></style>
