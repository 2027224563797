<template>
  <div class="add-incident-details-form">
    <h3>New incident status</h3>
    <div class="entry">
      <span>Date</span>
      <input v-model="newIncidentDataDetails.date" type="date" />
    </div>
    <div class="entry">
      <span>Time</span>
      <input v-model="newIncidentDataDetails.time" type="time" />
    </div>
    <div class="entry">
      <span>Status</span>
      <input
        placeholder="e.g. Work in progress"
        v-model="newIncidentDataDetails.status"
        type="text"
      />
    </div>
    <div class="entry">
      <span>Description</span>
      <textarea
        placeholder="e.g. The case is being investigated."
        v-model="newIncidentDataDetails.description"
        cols="15"
        rows="5"
      ></textarea>
    </div>
    <div class="entry-buttons">
      <button @click="$emit('closeModal')" class="red-button">Cancel</button>
      <button @click="saveData" class="green-button">
        <span class="material-icons">add</span> Save
      </button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'FormIncidentDetails',
  props: {
    editIncidentId: {
      type: Number,
    },
    editIncidentActionId: {
      type: Number,
    },
  },
  mounted() {
    if (this.editIncidentActionId !== null) {
      const editIncident = this.$parent.incidents.find(
        (incident) => incident.id === this.editIncidentId
      );
      const editIncidentAction =
        editIncident.actions[this.editIncidentActionId];

      this.newIncidentDataDetails = {
        date: moment(editIncidentAction.time).format('YYYY[-]MM[-]DD'),
        time: moment(editIncidentAction.time).format('HH:mm'),
        status: editIncidentAction.status,
        description: editIncidentAction.description,
      };
    }
  },
  data() {
    return {
      newIncidentDataDetails: {
        date: moment().format('YYYY[-]MM[-]DD'),
        time: moment().format('HH:mm'),
        status: '',
        description: '',
      },
    };
  },
  methods: {
    saveData() {
      if (this.editIncidentActionId !== null) {
        this.$store.commit('updateIncidentDetails', {
          incidentId: this.editIncidentId,
          actionId: this.editIncidentActionId,
          ...this.newIncidentDataDetails,
        });
      } else {
        this.$store.commit('addIncidentDetails', {
          id: this.editIncidentId,
          ...this.newIncidentDataDetails,
        });
      }

      this.$nextTick(() => {
        this.newIncidentDataDetails = {
          date: moment().format('YYYY[-]MM[-]DD'),
          time: moment().format('HH:mm'),
          status: '',
          description: '',
        };

        this.$emit('closeModal');
      });
    },
  },
};
</script>

<style></style>
