const favicon = {
  methods: {
    computeFavicon() {
      if (this.fullyOperational) {
        this.changeFavicon('favicon-green.ico');
        return;
      }

      if (this.nonOperational) {
        this.changeFavicon('favicon-red.ico');
        return;
      }

      this.changeFavicon('favicon-yellow.ico');
    },
    changeFavicon(icon) {
      let favicon = document.querySelector('link[rel="icon"]');
      if (favicon !== null) {
        favicon.href = icon;
      } else {
        favicon = document.createElement('link');
        favicon.rel = 'icon';
        favicon.href = icon;
        document.head.appendChild(favicon);
      }
    },
  }
}

export default favicon;
