<template>
  <div class="status-system">
    <span>{{ system.name }}</span>
    <!-- todo: handle -->
    <select v-if="isAdmin" v-model="systemStatus">
      <option :value="1">Operational</option>
      <option :value="2">Operational with incident</option>
      <option :value="3">Not operational</option>
    </select>
    <div
      class="status-system-icon"
      :class="[
        system.operationalStatus === 1
          ? 'green'
          : system.operationalStatus === 2
          ? 'yellow'
          : 'red'
      ]"
    >
      <svg
        width="100%"
        height="13.5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 89.858 100"
      >
        <path
          d="M51.284,83.261l8.749-5.8a19.169,19.169,0,0,1-8.406-2.486L33.039,64.973a19.2,19.2,0,0,1-6.692-5.63l-.019,10.484c-.015,7.518,2.2,13.092,6.25,15.869L34.6,86.781C39.145,88.636,45.015,87.414,51.284,83.261Z"
          style="fill: #ffffff;"
        />
        <path
          d="M26.373,46.047a20.132,20.132,0,0,1,5.562-5.278L52.87,26.9a19.477,19.477,0,0,1,7.536-3.179l-7.343-3.952c-7.024-3.782-13.607-4.3-18.588-1.494l-1.569,1.039c-4.195,3.223-6.5,8.951-6.513,16.351Z"
          style="fill: #ffffff;"
        />
        <path
          d="M78.349,33.379l-7.361-3.963a19.476,19.476,0,0,1,1.5,8.041l-.048,25.116a20.089,20.089,0,0,1-1.352,7.572l8.681-5.751c6.093-4.036,9.595-9.039,10.047-14.247l0-2.237C89.351,42.269,85.306,37.124,78.349,33.379Z"
          style="fill: #ffffff;"
        />
        <path
          d="M22.328,69.819l.019-10.484.026-13.3.02-10.384a30.91,30.91,0,0,1,1.732-10.526l-9.5,6.3C4.81,37.925-.375,46.3.021,55s6.324,16.57,16.687,22.149l7.617,4.1A30.108,30.108,0,0,1,22.328,69.819Z"
          style="fill: #ffffff;"
        />
        <path
          d="M54.958,16.244,62.3,20.2l10.582,5.7,7.361,3.963a29.647,29.647,0,0,1,9.591,7.833l.022-11.4C89.881,14.5,85.982,5.9,78.881,2.079S62.457-.262,52.626,6.25l-9.508,6.3A29.46,29.46,0,0,1,54.958,16.244Z"
          style="fill: #ffffff;"
        />
        <path
          d="M81.974,67.729,73.293,73.48,62.242,80.8l-8.75,5.8a29.871,29.871,0,0,1-10.628,4.637l7.586,4.084c5.836,3.141,11.5,4.709,16.675,4.683a21.415,21.415,0,0,0,11-2.95c7.485-4.461,11.619-13.4,11.641-25.17L89.791,60.5A30.877,30.877,0,0,1,81.974,67.729Z"
          style="fill: #ffffff;"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "SystemStatus",
  props: {
    system: {
      type: Object
    },
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.systemStatus = this.system.operationalStatus;
  },
  data() {
    return {
      systemStatus: 1
    };
  },
  watch: {
    systemStatus(status, oldStatus) {
      this.$store.commit("updateSystemStatus", {
        system: this.system,
        status,
        notify: status !== oldStatus && this.isAdmin
      });

      this.$nextTick(() => {
        this.$emit("computeFavicon");
      });
    }
  }
};
</script>

<style></style>
