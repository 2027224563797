<template>
  <div class="status-description">
    <div class="status-description-status">
      <div class="status-description-status-text" :class="[status]">
        <h1>{{ statusDescriptionText }}</h1>
        <h1 v-if="status === 'yellow'">{{ subStatusDescriptionText }}</h1>
      </div>
    </div>
    <div class="status-overall-last-fire">
      <h2>
        Last incident {{ lastIncident }}
        {{ lastIncident === 1 ? 'day' : 'days' }} ago
      </h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatusDescription',
  props: {
    status: {
      type: String,
      default: 'green',
    },
    limitedOperationalAmount: {
      type: String,
      default: '',
    },
    lastIncident: {},
  },
  computed: {
    statusDescriptionText() {
      if (this.status === 'green') {
        return 'All systems operational';
      }
      if (this.status === 'red') {
        return 'Something wrong';
      }

      return 'Systems operational,';
    },
    subStatusDescriptionText() {
      return `with ${this.limitedOperationalAmount}
      ${this.limitedOperationalAmount === 'one' ? 'system' : 'systems'}
      having an incident`;
    },
  },
};
</script>

<style></style>
