<template>
  <div class="add-incident-form">
    <h3>New incident</h3>
    <div class="entry">
      <span>Date</span>
      <input v-model="newIncidentData.date" type="date" />
    </div>
    <div class="entry">
      <span>Time</span>
      <input v-model="newIncidentData.time" type="time" />
    </div>
    <div class="entry">
      <span>Fire</span>
      <select v-model="newIncidentData.fire">
        <option :value="1">&#x1F525;</option>
        <option :value="2">&#x1F525;&#x1F525;</option>
        <option :value="3">&#x1F525;&#x1F525;&#x1F525;</option>
      </select>
    </div>
    <div class="entry">
      <span>Description</span>
      <textarea
        placeholder="e.g. Some users have reported issues with login page."
        v-model="newIncidentData.description"
        cols="15"
        rows="5"
      ></textarea>
    </div>
    <div class="entry-buttons">
      <button @click="$emit('closeModal')" class="red-button">Cancel</button>
      <button @click="saveData" class="green-button">
        <span class="material-icons">add</span> Save
      </button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'FormIncident',
  props: {
    editIncidentId: {
      type: Number,
    },
  },
  mounted() {
    if (this.editIncidentId !== null) {
      const editIncident = this.$parent.incidents.find(
        (incident) => incident.id === this.editIncidentId
      );

      this.newIncidentData = {
        date: moment(editIncident.date).format('YYYY[-]MM[-]DD'),
        time: moment(editIncident.date).format('HH:mm'),
        fire: editIncident.fire,
        description: editIncident.description,
      };
    }
  },
  data() {
    return {
      newIncidentData: {
        date: moment().format('YYYY[-]MM[-]DD'),
        time: moment().format('HH:mm'),
        fire: 1,
        description: '',
      },
    };
  },
  methods: {
    saveData() {
      if (this.editIncidentId !== null) {
        this.$store.commit('updateIncident', {
          id: this.editIncidentId,
          ...this.newIncidentData,
        });
      } else {
        this.$store.commit('addIncident', this.newIncidentData);
      }

      this.$nextTick(() => {
        this.newIncidentData = {
          date: moment().format('YYYY[-]MM[-]DD'),
          time: moment().format('HH:mm'),
          fire: 1,
          reportId: null,
          description: '',
        };

        this.$emit('closeModal');
      });
    },
  },
};
</script>

<style></style>
